import API_CONFIG from './apiConfig';
export default {
  //************* Category API ********************//
  addCategory: async function (data) {
    try {
      const response = await API_CONFIG.post('category/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllCategory: async function (data) {
    try {
      const response = await API_CONFIG.post('category', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleCategory: async function (id) {
    try {
      const response = await API_CONFIG.get(`category/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateCategory: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`category/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteCategory: async function (id) {
    try {
      const response = await API_CONFIG.delete(`category/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  //************* FAQ'S API ********************//

  addGeneralFaq: async function (data) {
    try {
      const response = await API_CONFIG.post('faq/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getGeneralFaq: async function (data) {
    try {
      const response = await API_CONFIG.post('faq', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteGeneralFaq: async function (id) {
    try {
      const response = await API_CONFIG.delete(`faq/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateGeneralFaq: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`faq/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getGeneralFaqById: async function (id) {
    try {
      const response = await API_CONFIG.get(`faq/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  //************* BLOG API ********************//

  addBlog: async function (data) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('title', data.title);
      formData.append('description', data.description);
      const response = await API_CONFIG.post('blog/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // const response = await API_CONFIG.post('blog/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getBlog: async function (data) {
    try {
      const response = await API_CONFIG.post('blog', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteBlog: async function (id) {
    try {
      const response = await API_CONFIG.delete(`blog/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  editBlog: async function (id, data) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('title', data.title);
      formData.append('description', data.description);
      const response = await API_CONFIG.put(`blog/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleBlog: async function (id) {
    try {
      const response = await API_CONFIG.get(`blog/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  // -----job-status----
  ChangeJobStatus: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`jobs/change-job-status/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  // -----job-approved----
  ChangeJobApproved: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`jobs/approve-job/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  // CONTACT LIST
  getContactList: async function (data) {
    try {
      const response = await API_CONFIG.post('contact-us', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleContactData: async function (id) {
    try {
      const response = await API_CONFIG.get(`contact-us/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  // -----change-password
  changePassword: async function (data) {
    try {
      const response = await API_CONFIG.post('auth/change-password', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  //************* Currency API ********************//
  addCurrency: async function (data) {
    try {
      const response = await API_CONFIG.post('currency/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllCurrency: async function (data) {
    try {
      const response = await API_CONFIG.post('currency', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleCurrency: async function (id) {
    try {
      const response = await API_CONFIG.get(`currency/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateCurrency: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`currency/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteCurrency: async function (id) {
    try {
      const response = await API_CONFIG.delete(`currency/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //************* PayType API ********************//
  addPayType: async function (data) {
    try {
      const response = await API_CONFIG.post('pay-type/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllPayType: async function (data) {
    try {
      const response = await API_CONFIG.post('pay-type', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSinglePayType: async function (id) {
    try {
      const response = await API_CONFIG.get(`pay-type/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updatePayType: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`pay-type/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deletePayType: async function (id) {
    try {
      const response = await API_CONFIG.delete(`pay-type/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //************* JobType API ********************//
  addJobType: async function (data) {
    try {
      const response = await API_CONFIG.post('job-type/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllJobType: async function (data) {
    try {
      const response = await API_CONFIG.post('job-type', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleJobType: async function (id) {
    try {
      const response = await API_CONFIG.get(`job-type/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateJobType: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`job-type/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteJobType: async function (id) {
    try {
      const response = await API_CONFIG.delete(`job-type/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //************* ApplyBy API ********************//
  addApplyBy: async function (data) {
    try {
      const response = await API_CONFIG.post('apply-by/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllApplyBy: async function (data) {
    try {
      const response = await API_CONFIG.post('apply-by', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleApplyBy: async function (id) {
    try {
      const response = await API_CONFIG.get(`apply-by/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateApplyBy: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`apply-by/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteApplyBy: async function (id) {
    try {
      const response = await API_CONFIG.delete(`apply-by/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  // --------get-user-data---------
  getUserData: async function (data) {
    try {
      const response = await API_CONFIG.post('users', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserById: async function (id, data) {
    try {
      const response = await API_CONFIG.get(`users/find/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  // --------get-user-data---------
  getSubscribeData: async function (data) {
    try {
      const response = await API_CONFIG.post('newsletter', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteSubscribeData: async function (id) {
    try {
      const response = await API_CONFIG.delete(`newsletter/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //************* Tag API ********************//
  addTag: async function (data) {
    try {
      const response = await API_CONFIG.post('tags/create', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllTag: async function (data) {
    try {
      const response = await API_CONFIG.post('tags', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleTag: async function (id) {
    try {
      const response = await API_CONFIG.get(`tags/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateTag: async function (id, data) {
    try {
      const response = await API_CONFIG.put(`tags/update/${id}`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteTag: async function (id) {
    try {
      const response = await API_CONFIG.delete(`tags/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //************* Job API ********************//
  getAllJob: async function (data) {
    try {
      const response = await API_CONFIG.post('jobs', data);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getSingleJob: async function (id) {
    try {
      const response = await API_CONFIG.get(`jobs/find/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteJob: async function (id) {
    try {
      const response = await API_CONFIG.delete(`jobs/delete/${id}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  recommendedJob: async function (data) {
    try {
      const response = await API_CONFIG.post(`jobs/recommended-jobs`, data);
      return response;
    } catch (error) {
      throw error.response;
    }
  }
};
