import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import API_UTILS from '../../../api';
import { useLocation } from 'react-router-dom';

const User = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API_UTILS.getUserById(location.state.userData.id);
        setData(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <MainCard title="General Details">
        <Typography variant="body2">Loading...</Typography>
      </MainCard>
    );
  }

  const downloadResume = async (resume, e) => {
    console.log(resume);

    e.stopPropagation();
    if (resume) {
      try {
        const response = await fetch(`${process.env.REACT_APP_PHOTO_URL}/${resume}`);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        console.log(url);

        tempLink.setAttribute('download', resume.split('/').pop() || 'resume.pdf');

        document.body.appendChild(tempLink);
        tempLink.click();

        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the resume:', error);
      }
    } else {
      console.error('Resume not found');
    }
  };

  return (
    <MainCard title="User Details">
      <Typography variant="body2">
        {data ? (
          <div style={{ position: 'relative' }}>
            {(data?.jobSeekerProfile?.profile_photo || data?.employerProfile?.logo) && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>{data?.jobSeekerProfile?.profile_photo ? 'Profile Photo' : 'Logo'}: </h3>
                <img
                  src={`${process.env.REACT_APP_PHOTO_URL}/${data?.jobSeekerProfile?.profile_photo || data?.employerProfile?.logo}`}
                  alt="avatar"
                  width="100"
                  height="100"
                />
              </div>
            )}
            <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>Full Name: </h3>
              <span>{data.name}</span>
            </div>
            <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>Email: </h3>
              <span>{data.email}</span>
            </div>
            <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>Phone: </h3>
              <span>{data.mobile_no}</span>
            </div>
            <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>Role: </h3>
              <span>{data.role}</span>
            </div>
            {data?.employerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Company Name: </h3>
                <a href={data?.employerProfile?.website} style={{ color: '#364152' }} target="__blank">
                  {data?.employerProfile?.company_name}
                </a>
              </div>
            )}
            {data?.employerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Country: </h3>
                <span>{data?.employerProfile?.country}</span>
              </div>
            )}
            {data?.jobSeekerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Job Title: </h3>
                <span>{data?.jobSeekerProfile?.job_title}</span>
              </div>
            )}
            {data?.jobSeekerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Location: </h3>
                <span>{data?.jobSeekerProfile?.location}</span>
              </div>
            )}
            {data?.jobSeekerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Experience: </h3>
                <span>{data?.jobSeekerProfile?.experience}</span>
              </div>
            )}
            {data?.jobSeekerProfile && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Birth Date: </h3>
                <span>{data?.jobSeekerProfile?.birth_date}</span>
              </div>
            )}
            {data?.jobSeekerProfile && data?.jobSeekerProfile.portfolio_link && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: '0' }}>Portfolio Link: </h3>
                <span>{data?.jobSeekerProfile?.portfolio_link}</span>
              </div>
            )}
            {data?.jobSeekerProfile && data?.jobSeekerProfile.resume && (
              <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                <h3 style={{ margin: 0 }}>Resume:</h3>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadResume(data.jobSeekerProfile.resume, e);
                  }}
                  style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  role="link"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      downloadResume(data.jobSeekerProfile.resume, e);
                    }
                  }}
                >
                  Download Resume
                </span>
              </div>
            )}
          </div>
        ) : (
          'No Data'
        )}
      </Typography>
    </MainCard>
  );
};

export default User;
