// assets
import { IconKey } from '@tabler/icons-react';

// constant
const icons = {
  IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'collapse',
      title: 'Category',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'category_list',
          title: 'Category List',
          type: 'item',
          url: '/category/category-list',
          target: false
        },
        {
          id: 'category_form',
          title: 'Add Category',
          type: 'item',
          url: '/category/category-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Currency',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'currency-list',
          title: 'Currency List',
          type: 'item',
          url: '/currency/currency-list',
          target: false
        },
        {
          id: 'currency-form',
          title: 'Add Currency',
          type: 'item',
          url: '/currency/currency-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Pay-Type',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'pay-type',
          title: 'Pay-Type List',
          type: 'item',
          url: '/pay-type/pay-type-list',
          target: false
        },
        {
          id: 'pay-type-form',
          title: 'Add Pay-Type',
          type: 'item',
          url: '/pay-type/pay-type-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Job-Type',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'job-type',
          title: 'Job-Type List',
          type: 'item',
          url: '/job-type/job-type-list',
          target: false
        },
        {
          id: 'job-type-form',
          title: 'Add Job-Type',
          type: 'item',
          url: '/job-type/job-type-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Apply By',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'apply-by',
          title: 'Apply By List',
          type: 'item',
          url: '/apply-by/apply-by-list',
          target: false
        },
        {
          id: 'apply-by-form',
          title: 'Add Apply By',
          type: 'item',
          url: '/apply-by/apply-by-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Jobs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'job-list',
          title: 'Jobs List',
          type: 'item',
          url: '/job/job-list',
          target: false
        }
        // {
        //   id: 'recommended-job-list',
        //   title: 'Recommended Jobs List',
        //   type: 'item',
        //   url: '/job/recommended-job-list',
        //   target: false
        // }
      ]
    },
    {
      id: 'collapse',
      title: 'Faqs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'faq',
          title: 'Add Faq',
          type: 'item',
          url: '/faq/faq-form',
          target: false
        },
        {
          id: 'faq-list',
          title: 'Faq List',
          type: 'item',
          url: '/faq/faq-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Blogs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'blog-form',
          title: 'Add Blog',
          type: 'item',
          url: '/blog/blog-form',
          target: false
        },
        {
          id: 'blog-list',
          title: 'Blog List',
          type: 'item',
          url: '/blog/blog-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Tags',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'tag-form',
          title: 'Add Tag',
          type: 'item',
          url: '/tag/tag-form',
          target: false
        },
        {
          id: 'tag-list',
          title: 'Tag List',
          type: 'item',
          url: '/tag/tag-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Contact',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'contact-list',
          title: 'Contact List',
          type: 'item',
          url: '/contact/contact-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Subscribe',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'subscribe-list',
          title: 'Subscribe List',
          type: 'item',
          url: '/subscribe/subscribe-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'User List',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'user-list',
          title: 'User List',
          type: 'item',
          url: '/users/user-list',
          target: false
        }
      ]
    }
  ]
};

export default pages;
