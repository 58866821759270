import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from 'ui-component/protectedRoutes';
import User from 'views/pages/users/user';
import ChangePassword from 'views/pages/accountSetting/changePassword';
import Job from 'views/pages/job/jobs';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

//page routing
const CurrencyList = Loadable(lazy(() => import('views/pages/currency/currencyList')));
const CurrencyForm = Loadable(lazy(() => import('views/pages/currency/currencyForm')));
const PayTypeList = Loadable(lazy(() => import('views/pages/payType/payTypeList')));
const PayTypeForm = Loadable(lazy(() => import('views/pages/payType/payTypeForm')));
const JobTypeList = Loadable(lazy(() => import('views/pages/jobType/jobTypeList')));
const JobTypeForm = Loadable(lazy(() => import('views/pages/jobType/jobTypeForm')));
const ApplyByList = Loadable(lazy(() => import('views/pages/applyBy/applyByList')));
const ApplyByForm = Loadable(lazy(() => import('views/pages/applyBy/applyByForm')));
const CategoryList = Loadable(lazy(() => import('views/pages/category/categoryList')));
const CategoryForm = Loadable(lazy(() => import('views/pages/category/categoryForm')));
const JobList = Loadable(lazy(() => import('views/pages/job/jobList')));
const RecommendedJobList = Loadable(lazy(() => import('views/pages/job/recommendedJobList')));
const FaqForm = Loadable(lazy(() => import('views/pages/faq')));
const FaqList = Loadable(lazy(() => import('views/pages/faq/faqList')));
const BlogForm = Loadable(lazy(() => import('views/pages/blog/blogForm')));
const BlogListForm = Loadable(lazy(() => import('views/pages/blog/blogList')));
const TagForm = Loadable(lazy(() => import('views/pages/tags/tagForm')));
const TagList = Loadable(lazy(() => import('views/pages/tags/tagList')));
const UserList = Loadable(lazy(() => import('views/pages/users/userList')));
const ContactList = Loadable(lazy(() => import('views/pages/contact/contactList')));
const SubscribeList = Loadable(lazy(() => import('views/pages/subscribe/subscribeList')));
const JobStatusForm = Loadable(lazy(() => import('views/pages/job/jobStatus')));
const JobApprovedForm = Loadable(lazy(() => import('views/pages/job/jobApproved')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    {
      path: 'account-setting',
      children: [
        {
          path: '',
          element: <ChangePassword />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'currency',
      children: [
        {
          path: 'currency-list',
          element: <CurrencyList />
        },
        {
          path: 'currency-form',
          element: <CurrencyForm />
        },
        {
          path: 'currency-form/edit/:id',
          element: <CurrencyForm />
        }
      ]
    },
    {
      path: 'apply-by',
      children: [
        {
          path: 'apply-by-list',
          element: <ApplyByList />
        },
        {
          path: 'apply-by-form',
          element: <ApplyByForm />
        },
        {
          path: 'apply-by-form/edit/:id',
          element: <ApplyByForm />
        }
      ]
    },
    {
      path: 'pay-type',
      children: [
        {
          path: 'pay-type-list',
          element: <PayTypeList />
        },
        {
          path: 'pay-type-form',
          element: <PayTypeForm />
        },
        {
          path: 'pay-type-form/edit/:id',
          element: <PayTypeForm />
        }
      ]
    },
    {
      path: 'job-type',
      children: [
        {
          path: 'job-type-list',
          element: <JobTypeList />
        },
        {
          path: 'job-type-form',
          element: <JobTypeForm />
        },
        {
          path: 'job-type-form/edit/:id',
          element: <JobTypeForm />
        }
      ]
    },
    {
      path: 'category',
      children: [
        {
          path: 'category-list',
          element: <CategoryList />
        },
        {
          path: 'category-form',
          element: <CategoryForm />
        },
        {
          path: 'category-form/edit/:id',
          element: <CategoryForm />
        }
      ]
    },
    {
      path: 'job',
      children: [
        {
          path: 'job-list/view/:id',
          element: <Job />
        },
        {
          path: 'job-list',
          element: <JobList />
        },
        {
          path: 'recommended-job-list',
          element: <RecommendedJobList />
        },
        {
          path: 'job-status-form/edit/:id',
          element: <JobStatusForm />
        },
        {
          path: 'job-approved-form/edit/:id',
          element: <JobApprovedForm />
        }
      ]
    },
    {
      path: 'faq',
      children: [
        {
          path: 'faq-form',
          element: <FaqForm />
        },
        {
          path: 'faq-list',
          element: <FaqList />
        },
        {
          path: 'faq-form/edit/:id',
          element: <FaqForm />
        }
      ]
    },
    {
      path: 'blog',
      children: [
        {
          path: 'blog-form',
          element: <BlogForm />
        },
        {
          path: 'blog-list',
          element: <BlogListForm />
        },
        {
          path: 'blog-form/edit/:id',
          element: <BlogForm />
        }
      ]
    },
    {
      path: 'tag',
      children: [
        {
          path: 'tag-form',
          element: <TagForm />
        },
        {
          path: 'tag-list',
          element: <TagList />
        },
        {
          path: 'tag-form/edit/:id',
          element: <TagForm />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'user-list',
          element: <UserList />
        },
        {
          path: 'user-list/view/:id',
          element: <User />
        }
      ]
    },
    {
      path: 'subscribe',
      children: [
        {
          path: 'subscribe-list',
          element: <SubscribeList />
        }
      ]
    },
    {
      path: 'contact',
      children: [
        {
          path: 'contact-list',
          element: <ContactList />
        }
      ]
    }
  ]
};

export default MainRoutes;
